import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "75%",
    marginTop: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2)
    // margin: "auto",
    // width: "100%"
    // marginLeft: "auto",
    // marginRight: "auto"
  },
  image: {
    width: 100,
    height: 100
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%"
  }
}));

Coin.propTypes = {
  currency_id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  total_cost: PropTypes.number.isRequired,
  total_net_profit: PropTypes.number.isRequired,
  total_gross_profit: PropTypes.number.isRequired
};

export default function Coin(props) {
  const classes = useStyles();
  const {
    currency_id,
    name,
    logo,
    total_cost,
    total_net_profit,
    total_gross_profit
  } = props;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item>
            <img
              className={[classes.image, classes.img]}
              alt={`${name} Logo`}
              src={logo}
              width={100}
            />
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant="subtitle1">
                  {name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Gross: {"$" + total_gross_profit.toFixed(2)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Costs: {"$" + total_cost.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">
                {"$" + total_net_profit.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
