import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import CircularProgress from "@material-ui/core/CircularProgress";

import Coin from "./Coin";
import Gpu from "./Gpu";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  rightColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  progress: {
    padding: theme.spacing(2)
  }
}));

App.propTypes = {
  gpus: PropTypes.array.isRequired,
  coins: PropTypes.array.isRequired,
  onSelectGpu: PropTypes.func.isRequired,
  selectedGpu: PropTypes.object
};

export default function App(props) {
  const classes = useStyles();
  const { gpus, coins, selectedGpu, onSelectGpu } = props;

  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <List component="nav" className={classes.root} aria-label="GPU List" />

        {gpus.map(g => (
          <Gpu
            {...g}
            onSelect={onSelectGpu}
            selected={selectedGpu && selectedGpu.id == g.id}
          />
        ))}
      </Grid>
      <Grid item xs={12} sm={6} className={classes.rightColumn}>
        {coins.map(c => (
          <Coin {...c} />
        ))}
        {!selectedGpu && coins.length == 0 ? (
          <h3>← Select a GPU to Calculate Estimated Profits</h3>
        ) : null}
        {selectedGpu && coins.length == 0 ? (
          <CircularProgress className={classes.progress} color="secondary" />
        ) : null}
      </Grid>
    </Grid>
  );
}
