import React from "react";
import Api from "./lib/Api";

import App from "./App";

class AppContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coins: [],
      gpus: [],
      selectedGpu: false
    };

    this.api = new Api("https://tranmon-service-production.herokuapp.com");
  }

  async componentWillMount() {
    let gpus = await this.api.get("gpus");
    this.setState({ gpus });
  }

  selectGpu = async gpu => {
    console.log("onSelectGpu", gpu);
    this.setState({ selectedGpu: gpu }, async () => {
      let d = {
        gpu: {}
      };
      d.gpu[`${this.state.selectedGpu.name}`] = 1;
      let coins = await this.api.post("profitabilities/by_gpu", d);
      coins = coins.slice(0, 3);
      this.setState({ coins });
    });
  };

  render = () => {
    const { coins, gpus, selectedGpu } = this.state;
    console.log("Coins!", coins);
    console.log("Gpus!", gpus);
    console.log("selectedGpu!", selectedGpu);

    return (
      <App
        gpus={gpus}
        coins={coins}
        selectedGpu={selectedGpu}
        onSelectGpu={this.selectGpu}
      />
    );

    // const renderGpus = gpu => {
    //   let items = [];
    //   for (var gpu of gpus) {
    //     console.log("gpu", gpu);
    //     items.push(
    //       <Gpu
    //         onSelect={gpu => {
    //           this.onSelectGpu(gpu);
    //         }}
    //         key={gpu.id}
    //         {...gpu}
    //       />
    //     );
    //   }
    //   return items;
    // };
    //
    // const renderCoins = coins => {
    //   let items = [];
    //   for (var coin of coins) {
    //     console.log("coin", coin);
    //     items.push(<Coin key={coin.currency_id} {...coin} />);
    //   }
    //   return items;
    // };
  };
}

export default AppContainer;
