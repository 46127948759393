export default class Api {
  constructor(hostname) {
    this.hostname = hostname;
  }

  get = async function(endpoint) {
    const raw = await fetch(`${this.hostname}/${endpoint}`);
    const data = await raw.json();
    return data;
  };

  post = async function(endpoint, data) {
    const raw = await fetch(`${this.hostname}/${endpoint}`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });
    const response = await raw.json();
    return response;
  };
}
